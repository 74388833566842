import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILogin } from '../auth/auth.service';

export interface IData {
	email: string;
	token: string;
	id: number;
}

export interface IPlanData {
	amount: number;
	planName: string;
	planId: string;
	dialogName: string;
	label: string;
	newPlanPeriod: string;
	currencyDetail: string;
	planType: string,
	stripeAccountId: string,
	stripeElement:any;
	paypalPlanId: string;
}

export interface ISendMessageContext {
	userData?: IData;
	userDataObject?: ILogin;
	subscribedId?: string;
	planData?: IPlanData;
	planName?: string;
    email?: string;
    showEarlyBirdPrice?: boolean;
    showGetTicketModal?: any;
}

@Injectable({
	providedIn: 'root',
})

export class MessageService {
	private messageSource = new BehaviorSubject<ISendMessageContext | string>('default message');
	public currentMessage: Observable<ISendMessageContext | string> = this.messageSource.asObservable();

	public constructor() { }

	/**
	 * It is used for sending a message.
	 * @param message : object containing message information.
	 */
	public sendMessage(message: ISendMessageContext) {
		// return new Promise(messageSent => {
		this.messageSource.next(message);
		//   messageSent();
		// });
	}

	/**
	 * Next function
	 */
	public next(): void {
		this.messageSource.next(null);
	}
}
